var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"token","avatar":"key","suppliers":_vm.suppliers,"features":{
    search: false,
    create: true,
    update: true,
    delete: false,
  }},scopedSlots:_vm._u([{key:"row",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('h5',[_vm._v(_vm._s(item.name))]),_vm._v(" "+_vm._s(_vm.$t('created'))+" "+_vm._s(_vm._f("calendar")(item.created))+" ")]),_c('div',{staticClass:"col-12 col-xl-6"},[_c('a-input-password',{attrs:{"read-only":true,"size":"large"},model:{value:(item.bearer),callback:function ($$v) {_vm.$set(item, "bearer", $$v)},expression:"item.bearer"}},[_c('a-icon',{staticClass:"text-success",attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)])])])]}},{key:"actions",fn:function(ref){
  var item = ref.item;
return [_c('a-button',{attrs:{"shape":"circle","icon":"copy","type":"link"},on:{"click":function () { return _vm.onCopy(item); }}}),_c('router-link',{attrs:{"to":("tokens/" + (item.id) + "/update")}},[_c('a-button',{attrs:{"shape":"circle","icon":"setting"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }