<template>
  <j-abm
    name="token"
    avatar="key"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: false,
    }">
    <template #row="{item}">
      <div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-xl-6">
              <h5>{{ item.name }}</h5>
              {{ $t('created') }} {{ item.created | calendar }}
            </div>
            <div class="col-12 col-xl-6">
              <!-- Key -->
              <a-input-password
                v-model="item.bearer"
                :read-only="true"
                size="large">
                <a-icon
                  slot="prefix"
                  type="lock"
                  class="text-success" />
              </a-input-password>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions="{item}">
      <!-- Copy -->
      <a-button
        shape="circle"
        icon="copy"
        type="link"
        @click="() => onCopy(item)" />
      <!-- Edit -->
      <router-link :to="`tokens/${item.id}/update`">
        <a-button
          shape="circle"
          icon="setting" />
      </router-link>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import JAbm from '@/views/shared/abm/search'
export default {
  components: {
    JAbm,
  },
  data: function () {
    return {
      acl,
    }
  },
  computed: {
    suppliers() {
      return {
        fetchAll: page => lms.account.fetchTokens(this.account, page),
      }
    },
  },
  methods: {
    onCopy(item) {
      navigator.clipboard.writeText(item.bearer)
      this.$message.success(this.$t('msg.copied'))
    },
  },
}
</script>
